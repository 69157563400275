import React, { useMemo, useState } from 'react';
import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import { MoveToTop } from 'src/animations';
import Table from 'src/components/shared/tables';
import { Link } from 'react-router-dom';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import SharedTime from 'src/components/shared/SharedTime';
import useOrdersHooks from 'src/hooks/orders';
import axiosInstance from 'src/helper/AxiosInstance';
import { useAlert } from 'src/hooks/alerts';
import { AxiosError } from 'axios';
import Alert from 'src/components/shared/Alert';
import useIssuesHook from 'src/hooks/Issues';
import Modal from 'src/components/shared/Modal';
import { GetIssue } from 'src/hooks/Issues/id';

export default function index() {
  let rerender: boolean = true;
  const { pagination, isLoading, issues, GetIssues, issuesType } = useIssuesHook();
  const alert = useAlert();
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  const [typeName, setTypeName] = useState('');
  const [type, setType] = useState('');
  const [issue, setIssue] = useState<any>(null);
  const [visibleIssue, setVisibleIssue] = useState<boolean>(false);
  const [replay, setReplay] = useState<string>('');

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!typeName || !type)
      return alert.show({
        text: 'Choose Issue Type   ',
        visible: true
      });
    const { status } = await axiosInstance.post('issues/', { message, subject, type, typeName });
    if (status === 201) {
      setVisible(false);
      await GetIssues();
    }
  };

  async function DeleteItem(id: string) {
    try {
      const { status } = await axiosInstance.delete(`issues/${id}`);
      if (status === 200) {
        await GetIssues();
        alert.show({
          text: 'Issue deleted SuccessFully  ',
          visible: true
        });
      }
    } catch (error: AxiosError | any) {
      const err = error.response?.data;
      if (err) {
        const _serviceError = err?.message?.error?.message;
        alert.show({
          text: _serviceError || err?.message,
          visible: true
        });
      }
    }
  }

  const formatTimeMsg = (time: string) => {
    const date = new Date(Number(time));
    const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    const ampm = date.getHours() ? 'PM' : 'AM';
    return `${hours}:${minutes} ${ampm}`;
  };

  const handleReplay = async (e: any) => {
    e.preventDefault();
    try {
      console.log(issue);
      const { status } = await axiosInstance.put('issues/' + issue.id, { replay });
      if (status === 200) {
        setReplay('');
        const { data } = await axiosInstance.get('issues/data/' + issue.id);
        console.log(data);
        setIssue(data);
      }
    } catch (error: AxiosError | any) {
      const err = error.response?.data;
      if (err) {
        const _serviceError = err?.message?.error?.message;
        alert.show({
          text: _serviceError || err?.message,
          visible: true
        });
      }
    }
  };

  const MEMO_TABLE = useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th className="w-1/6">ID</th>
              <th className="w-1/3">Subject</th>
              <th className="w-1/3">Message</th>
              <th className="w-1/12">Type</th>
              <th className="w-1/12">Actions</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {issues?.map((issue: any, i: number) => (
                <tr key={i}>
                  <td
                    className="w-1/6 text-sky-500 font-semibold tabular-nums cursor-pointer hover:opacity-70"
                    onClick={async () => {
                      const data = await GetIssue(issue.id);
                      setIssue(data);
                      setVisibleIssue(true);
                    }}
                  >
                    #{issue.issueId}
                  </td>
                  <td className="w-1/3">{issue?.subject}</td>
                  <td className="w-1/3">{issue?.message}</td>
                  <td className="w-1/12">{issue.typeName}</td>
                  <td className="w-1/12">
                    <div className="btn-with-icon text-content w-full flex justify-between items-center">
                      <Icon
                        icon="ri:eye-line"
                        width={30}
                        height={30}
                        color="#ccc"
                        className="outline-btn p-1 rounded-full cursor-pointer "
                        onClick={async () => {
                          const data = await GetIssue(issue.id);
                          setIssue(data);
                          setVisibleIssue(true);
                        }}
                      />

                      <Icon
                        icon="ic:baseline-delete"
                        width={30}
                        height={30}
                        color="red"
                        className=" p-1 rounded-full cursor-pointer"
                        onClick={async () => await DeleteItem(issue.id)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </>
          );
        }}
        title={'Issues table'}
        isEmpty={!issues?.length}
        pagination={pagination}
        searchProps={{
          defaultValue: pagination.search_key,
          onKeyDown: ({ key, target }: any) => {
            if (key === 'Enter') {
              GetIssues({
                search_key: target.value
              });
            }
          }
        }}
        onNextClick={() => GetIssues({ page: pagination.current_page + 1 })}
        onPreviousClick={() => GetIssues({ page: pagination.current_page - 1 })}
        loading={isLoading}
      />
    );
  }, [pagination, isLoading, issues]);

  const IssueMemo = useMemo(() => {
    return issue
      ? issue?.issueMessage?.map((message: any, index: number) => (
          <div
            key={index}
            className={`w-full flex text-white px-2 md:text-base text-sm  ${
              message?.nickName === 'admin' ? '  ' : ' flex-row-reverse'
            } items-center gap-x-2`}
          >
            {message?.nickName === 'admin' ? (
              <span className="bg-stone-700 p-3 rounded-full">admin</span>
            ) : (
              <span className="bg-sky-500 p-3 rounded-full">You</span>
            )}
            <div
              className={`${
                message?.nickName === 'admin' ? 'bg-stone-700' : 'bg-sky-500'
              } rounded-xl md:w-1/2 w-10/12 p-2 flex flex-col gap-y-2`}
            >
              <span className="w-fit">{message?.message}</span>
              <div
                className={`w-full text-sm opacity-80 flex gap-x-2 ${
                  message?.nickName === 'admin' ? 'justify-end' : 'justify-start'
                }`}
              >
                <span>{new Date(Number(message?.messageAt)).toLocaleDateString()}</span>
                <span> {formatTimeMsg(message?.messageAt)}</span>
              </div>
            </div>
          </div>
        ))
      : '';
  }, [issue, isLoading]);

  return (
    <div className=" space-y-2 px-7 flex w-full flex-col items-start justify-start h-full">
      <span className="text-2xl font-bold  ">SoBuy Issues</span>
      <div className="w-full flex justify-end">
        <button
          className="bg-sky-500 text-white px-6 py-3 rounded-md"
          onClick={() => setVisible(true)}
        >
          Create Issue
        </button>
      </div>

      {MEMO_TABLE}

      <Modal
        visible={visible}
        title="Create Issue"
        handleClose={() => setVisible(false)}
      >
        <form
          className="w-full flex flex-col gap-3 justify-center items-center"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col w-full gap-3">
            <span className="font-bold">Issue Type</span>
            <select
              className="border px-3 py-2 rounded-md text-gray-500"
              onChange={(e) => {
                setType(e.target.value.split('-')[0]);
                setTypeName(e.target.value.split('-')[1]);
              }}
            >
              <option>Choose Type</option>
              {issuesType?.map((type: any, index: number) => (
                <option key={index}>{type.type + '-' + type.typeName}</option>
              ))}
            </select>
          </div>

          <div className="flex flex-col w-full gap-3">
            <span className="font-bold">Subject</span>
            <input
              className="border px-3 py-2 rounded-md text-gray-500"
              placeholder="Issue Subject"
              type="text"
              required
              value={subject}
              onChange={(e: any) => setSubject(e.target.value)}
            />
          </div>

          <div className="flex flex-col w-full gap-3">
            <span className="font-bold">Message</span>
            <input
              className="border px-3 py-2 rounded-md text-gray-500"
              placeholder="Issue Message"
              type="text"
              required
              value={message}
              onChange={(e: any) => setMessage(e.target.value)}
            />
          </div>

          <button className="bg-sky-500 py-2 md:w-1/2 w-full text-white rounded-md">Submit</button>
        </form>
      </Modal>

      <Modal
        visible={visibleIssue}
        title="Issue History"
        handleClose={() => setVisibleIssue(false)}
      >
        <form
          className="w-full flex flex-col gap-3 justify-center items-start"
          onSubmit={handleReplay}
        >
          <span className="w-full border p-2 rounded-lg space-x-2 bg-stone-700 text-white">
            <span>issue id :</span>
            <span>{issue?.id}</span>
          </span>
          <span className="w-full border p-2 rounded-lg space-x-2 bg-stone-700 text-white">
            <span>Subject :</span>
            <span>{issue?.subject}</span>
          </span>
          <div
            className="flex flex-col justify-start items-center p-2 border-2 gap-3 w-full rounded-xl overflow-auto"
            style={{ height: '65vh' }}
          >
            {IssueMemo}
          </div>
          <div className="flex w-full items-center">
            <input
              type="text"
              placeholder="Replay For Issue"
              className="border rounded-l-md p-2 flex-1 outline-sky-500"
              required
              value={replay}
              onChange={(e) => setReplay(e.target.value)}
            />
            <button className="bg-sky-500 w-fit p-2 text-white rounded-r-md">
              <Icon
                icon="ic:baseline-send"
                className="text-white p-1 rounded-full cursor-pointer bg-sky-500 w-6 h-6 "
              />
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
}
