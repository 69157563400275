import axiosInstance from "src/helper/AxiosInstance";

export const GetIssue  =async (id:string) => {
  try {
    const { data } = await axiosInstance.get('issues/' + id);
    return data
  } catch (error) {
    console.log(error)
  }
}
