import axios, { AxiosError } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables';
import axiosInstance from 'src/helper/AxiosInstance';

const TrackngTable = (order: any, orderType: string) => {
  const [orderStatus, setOrderStatus] = useState<any>({});
  const [orderDg, setOrderDG] = useState<any>({});
  const [orderSouBuy, setOrderSoBuy] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        if (order.type === 'AE') {
          if (order?.order?.tracking_order_id)
            await axiosInstance
              .get(`orders/order_status/${order?.order?.tracking_order_id}`)
              .then(({ data }) => setOrderStatus(data));
        } else if (order.type === 'Sobuy') {
          if (order?.order?.sobuy?.trackingNumber) {
            await axiosInstance
              .get(`orders/sobuy/track/${order?.order?.id}`)
              .then(({ data }) => setOrderSoBuy(data))
              .then(() => console.log(orderSouBuy));
          }
        } else if (order?.order?.dg.awb_no)
          await axiosInstance.get(`orders/order_status/${order?.order?.dg.awb_no}?type=DG`);
        // .then(({data})=>setOrderDG(data.tracks))
      } catch (error: AxiosError | any) {
        const err = error.response;
        if (err?.data?.err?.code == 409) {
          setLoading(false);
        }

        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [order]);
  const MEMO_TABLE = useMemo(() => {
    if (order.type === 'AE') {
      if (orderStatus?.details?.details?.length) {
        return (
          <Table
            RenderHead={() => {
              return (
                <tr>
                  <th className="text-xs">Status</th>
                  <th className="text-xs">Address</th>
                  <th className="text-xs">Date</th>
                </tr>
              );
            }}
            RenderBody={() => {
              return (
                <>
                  {orderStatus?.details?.details.map((item: any, i: number) => {
                    return (
                      <tr key={i}>
                        <td>
                          <span
                            className="text-xs"
                            style={i == 0 ? { color: 'green' } : {}}
                          >
                            {item?.event_desc}
                          </span>
                        </td>
                        <td>
                          <span className="text-xs">
                            {item?.address ? (
                              item?.address
                            ) : (
                              <span
                                className=" bg-black"
                                style={
                                  i === 0
                                    ? {
                                        color: 'green',
                                        width: '100%',
                                        height: '1px',
                                        display: 'inline-block'
                                      }
                                    : { width: '100%', height: '1px', display: 'inline-block' }
                                }
                              ></span>
                            )}
                          </span>
                        </td>
                        <td>
                          <span
                            className="text-xs"
                            style={i == 0 ? { color: 'green' } : {}}
                          >
                            {SharedTime({ date: item?.event_date })}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </>
              );
            }}
            isEmpty={orderStatus?.details?.length}
            search={false}
          />
        );
      } else {
        return (
          <span className="text-sm text-red-500 text-center w-full mb-5">
            No Information Available Right Now
          </span>
        );
      }
    } else if (order.type === 'DG') {
      if (orderDg?.length) {
        return (
          <Table
            RenderHead={() => {
              return (
                <tr>
                  <th className="text-xs">Status</th>
                  <th className="text-xs">Address</th>
                  <th className="text-xs">Date</th>
                </tr>
              );
            }}
            RenderBody={() => {
              return (
                <>
                  {orderDg
                    .map((element: any, index: any, array: any) => array[array.length - 1 - index])
                    .map((item: any, i: number) => {
                      return (
                        <tr key={i}>
                          <td>
                            <span
                              className="text-xs"
                              style={i == 0 ? { color: 'green' } : {}}
                            >
                              {item?.Activites}
                            </span>
                          </td>
                          <td>
                            <span className="text-xs">
                              {item?.new_location ? (
                                item?.new_location
                              ) : (
                                <span
                                  className=" bg-black"
                                  style={
                                    i === 0
                                      ? {
                                          color: 'green',
                                          width: '100%',
                                          height: '1px',
                                          display: 'inline-block'
                                        }
                                      : { width: '100%', height: '1px', display: 'inline-block' }
                                  }
                                ></span>
                              )}
                            </span>
                          </td>
                          <td>
                            <span
                              className="text-xs"
                              style={i == 0 ? { color: 'green' } : {}}
                            >
                              {SharedTime({ date: item?.entry_date })}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </>
              );
            }}
            isEmpty={false}
            search={false}
          />
        );
      } else {
        return (
          <span className="text-sm text-red-500 text-center w-full mb-5">
            No Information Available Right Now
          </span>
        );
      }
    } else if (order.type === 'Sobuy') {
      console.log(order);
      if (orderSouBuy?.length) {
        return (
          <Table
            RenderHead={() => {
              return (
                <tr>
                  <th className="text-xs">Status</th>
                  <th className="text-xs">Date</th>
                </tr>
              );
            }}
            RenderBody={() => {
              return (
                <>
                  {orderSouBuy.map((item: any, i: number) => {
                    return (
                      <tr key={i}>
                        <td>
                          <span className={`text-xs ${i === 0 ? 'text-green-600' : ''}`}>
                            {item?.desc}
                          </span>
                        </td>
                        <td>
                          <span className={`text-xs ${i === 0 ? 'text-green-600' : ''}`}>
                            {SharedTime({ date: item?.processedAt })}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </>
              );
            }}
            isEmpty={!orderSouBuy?.length}
            search={false}
          />
        );
      } else {
        return (
          <span className="text-sm text-red-500 text-center w-full mb-5">
            No Information Available Right Now
          </span>
        );
      }
    }
  }, [orderStatus, orderDg, orderSouBuy]);
  return (
    <>
      {error ? (
        <div className="text-center w-full text-xl text-red-700 h-20 flex items-center justify-center">
          {error}
        </div>
      ) : (
        <div className="flex flex-col gap-y-2">
          {loading ? (
            <div className="w-full flex items-center justify-center">
              <TailSpin
                height="25"
                width="25"
                color="#253439"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            <>
              {order?.type === 'Sobuy' ? (
                <>
                  <div className="text-xs w-full flex justify-between">
                    <span className="flex-1">Order ID :</span>
                    <span className="flex-1 text-sky-600 text-center">{order.order.sobuy?.id}</span>
                  </div>
                  <div className="text-xs w-full flex justify-between">
                    <span className="flex-1">Tacking Number :</span>
                    <span className="flex-1 text-sky-600 text-center">
                      {order?.type === 'AE'
                        ? order.order.tracking_order_id || '-'
                        : order?.type === 'Sobuy'
                        ? order.order.sobuy?.trackingNumber
                        : ''}
                    </span>
                  </div>
                </>
              ) : (
                ''
              )}
              {order?.type === 'AE' ? (
                <div className="text-xs w-full flex justify-between">
                  <span className="flex-1">Tacking Number :</span>
                  <span className="flex-1 text-sky-600 text-center">
                    {order.order.tracking_order_id || '-'}
                  </span>
                </div>
              ) : (
                ''
              )}

              {MEMO_TABLE}
            </>
          )}

          {order.type === 'AE' ? (
            <a
              href={orderStatus?.official_website}
              target="_blank"
              className=" flex items-center justify-center text-sm bg-gray-300 rounded-lg px-2 py-1 w-fit self-end gap-x-1"
            >
              <span className="text-orange-500">Ali</span>
              <span className="text-red-900">Express</span>
              <span className="text-orange-500">Site</span>
              <span className="text-red-900">Tracking</span>
            </a>
          ) : order.type === 'DG' ? (
            <a
              href={order?.order?.dg?.tracking_url}
              target="_blank"
              className=" flex items-center justify-center text-sm bg-gray-300 rounded-lg px-2 py-1 w-fit self-end gap-x-1"
            >
              <span className="text-red-700">DIGGI</span>
              <span className="text-white">PACKS</span>
            </a>
          ) : (
            ''
          )}
        </div>
      )}
    </>
  );
};

export default TrackngTable;
