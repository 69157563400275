import { pick } from 'lodash';
import React, { useEffect, useState } from 'react';
import axiosInstance from 'src/helper/AxiosInstance';

const useIssuesHook = () => {
  let rerender = true;
  const [issues, setIssues] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [issuesType, setIssuesType] = useState<any[]>([]);
  const [pagination, setPagination] = useState<any>({
    search_key: '',
    page: 1
  });
  async function GetIssues(params?: any) {
    try {
      const paginate = pick({ ...pagination, ...params }, ['page', 'search_key']);

      const { data } = await axiosInstance.get('issues/', {
        params: paginate
      });
      setIssues(data.data);
      setPagination((ev: any) => ({
        ...ev,
        ...data.pagination
      }));
    } catch (error) {}
  }

  async function GetIssuesType() {
    try {
      const { data } = await axiosInstance.get('issues/sobuy/types');
      setIssuesType(Array.isArray(data) ? data : []);
    } catch (error) {
      console.log('Error fetching issue types:', error);
    }
  }

  useEffect(() => {
    if (rerender) {
      Promise.all([GetIssues(), GetIssuesType()]).finally(() => {
        setIsLoading(false);
      });

      rerender = false;
    }
  }, []);
  return { issues, isLoading, pagination, GetIssues, issuesType };
};

export default useIssuesHook;
