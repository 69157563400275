import { Dialog, Transition } from '@headlessui/react';
import { Icon } from '@iconify/react';
import React from 'react';
import { Fragment, useState } from 'react';

function Modal({
  visible,
  handleClose,
  title,
  children,

  size
}: {
  visible?: boolean;
  handleClose?: any;
  title?: string;
  children?: React.ReactNode;
  size?: string;
}) {
  return (
    <>
      <Transition
        appear
        show={visible}
        as={Fragment}
      >
        <Dialog
          as="div"
          className="relative z-20"
          onClose={() => undefined}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={[
                    'w-full transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all space-y-6',
                    size ? size : 'max-w-xl'
                  ].join(' ')}
                >
                  <div className="flex items-center justify-between gap-4">
                    {title ? (
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-neutral-900"
                      >
                        {title}
                      </Dialog.Title>
                    ) : null}
                    <button
                      type="button"
                      className="btn-with-icon !text-neutral-500 !bg-red-neutral-300 !rounded-full"
                      onClick={handleClose}
                    >
                      <Icon
                        icon="ion:close"
                        width={18}
                      />
                    </button>
                  </div>
                  {React.isValidElement(children) ? children : null}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default React.memo(Modal);
