import { Icon } from '@iconify/react';
import { AllHTMLAttributes, ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import useForm from 'src/hooks/useForm';
import Image from '../shared/Image';
import ItemList from '../shared/ItemList';
import Modal from '../shared/Modal';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { TailSpin } from 'react-loader-spinner';

export default function ProductsShipping({ order, refetch }: { order: any; refetch: any }) {
  const [visible, setVisible] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [groupShipping, setGroupShipping] = useState([]);
  const [shipType, setShipType] = useState({
    type: order.shipType.type || 'AE',
    payment: order.shipType.payment || 'Pay Now'
  });
  const [orderSoBuy, setOrderSoBuy] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        if (order) {
          const { data, status } = await axiosInstance.get(
            `/aliexpress/order/shipping/${order.id}`
          );
          if (status === 200) {
            setGroupShipping(data.shipping);
          }
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [order]);

  const { formik } = useForm({
    initialValues: {
      id: order?.id || order?._id,
      shipping: groupShipping || []
    },
    submitHandler: async (values, helper) => {
      if (!shipType.type) return setError('You Should Choose Shipping Type');
      if (!shipType.payment) return setError('You Should Choose Payment Type');
      const ship = values.shipping.filter((e: any) => e.service_name && e);
      try {
        if (groupShipping.length !== ship.length)
          return setError('You Should Choose Shipping Method For All Products');
        setDisabled(true);
        console.log(shipType, values);
        const { status } = await axiosInstance.post('orders/update-shipping', {
          ...values,
          shipType
        });
        if (status === 200) {
          setVisible(false);
        }
        refetch();
        setError('');
      } catch (error) {
        console.log(error);
      } finally {
        setDisabled(false);
      }
    }
  });

  const handleSoBuy = async () => {
    setShipType({ type: 'Sobuy', payment: 'Pay now' });
    setLoading(true);
    if (order.sobuy) setLoading(false);

    try {
      const response = await axiosInstance.get(`/orders/sobuy/preview/${order?.id || order?._id}`);

      console.log(response.data);

      if (response.status === 200) {
        refetch();
      } else {
        toast.error('Sobuy shipping is not available for this order');
        setVisible(false);
      }
    } catch (error) {
      toast.error('Sobuy shipping is not available for this order');
      setVisible(false);
    } finally {
      setLoading(false);
    }
  };

  const updateSobuyShipping = async () => {
    try {
      await axiosInstance
        .post(`/orders/sobuy/update/${order?.id || order?._id}`, {
          products: groupShipping.flatMap((ship: any) => ship.items)
        })
        .then(({ status }) => {
          if (status === 200) {
            refetch();
            setVisible(false);
            setError('');
          }
        });
    } catch (error) {}
  };

  function onShippingChange(ev: ChangeEvent<HTMLInputElement>) {
    const { dataset } = ev.target;
    const index = groupShipping?.findIndex((e: any) => e.store_id == dataset.store_id);
    const products: any = groupShipping?.find((e: any) => e.store_id == dataset.store_id);
    if (products) {
      let amount = dataset?.amount;
      const object = {
        service_name: dataset?.serviceName,
        store_id: Number(dataset?.store_id),
        amount,
        products: products.items,
        tracking: JSON.parse(dataset?.tracking as string)
      };
      formik.setFieldValue('shipping[' + index + ']', object);
      console.log(shipType);
    }
  }
  const { user } = useSelector((state: RootState) => state.auth);

  return (
    <>
      {['created'].includes(order.status) ? (
        <button
          type="button"
          className="btn-with-icon !gap-0  bg-secondary shrink-0 !rounded-full !p-2 !text-white !text-xs"
          onClick={() => setVisible(true)}
        >
          <Icon
            icon="material-symbols:edit-location-alt-outline-rounded"
            width="16"
          />
          <span>Update</span>
        </button>
      ) : user?.userType === 'admin' ? (
        <button
          type="button"
          className="btn-with-icon !gap-0  bg-secondary shrink-0 !rounded-full !p-2 !text-white !text-xs"
          onClick={() => setVisible(true)}
        >
          <Icon
            icon="material-symbols:edit-location-alt-outline-rounded"
            width="16"
          />
          <span>Update</span>
        </button>
      ) : null}
      <Modal
        title="Products shipping methods"
        size="!max-w-screen-lg"
        visible={visible}
        handleClose={() => {
          setError('');
          setVisible(false);
        }}
      >
        <form
          onSubmit={formik.handleSubmit}
          className="flex gap-2 flex-wrap "
        >
          <div className="flex flex-col w-full ">
            <span>Shipping Method</span>
            <div className="w-full flex flex-col justify-around my-3 gap-y-3">
              <div key={'AE'}>
                <input
                  type="radio"
                  name={'AE'}
                  id={`AE`}
                  className="form-radio shrink-0 peer !hidden text"
                  hidden
                  onClick={() => {
                    setShipType({ type: 'AE', payment: 'Pay Now' });
                  }}
                />
                <label
                  className={`flex-1 cursor-pointer    ${
                    shipType.type === 'AE' && 'border-blue-600'
                  } border-2 py-3 px-4 rounded transition-all block text-sm`}
                  htmlFor={`AE`}
                >
                  <div className="flex items-center gap-3 flex-wrap">
                    <p className="text-sm font-semibold">Aliexpress</p>
                  </div>
                </label>
              </div>
              <div key={'Sobuy'}>
                <input
                  type="radio"
                  name={'Sobuy'}
                  id={`Sobuy`}
                  className="form-radio shrink-0 peer !hidden text"
                  hidden
                  onClick={() => handleSoBuy()}
                />
                <label
                  className={`flex-1 cursor-pointer ${
                    shipType.type === 'Sobuy' && 'border-blue-600'
                  } border-2 py-3 px-4 rounded  transition-all block text-sm`}
                  htmlFor={`Sobuy`}
                >
                  <div className="flex items-center gap-3 flex-wrap w-full justify-between">
                    <p className="text-sm font-semibold">
                      SoBuy <span className="text-sm text-blue-500"></span>
                    </p>
                  </div>
                </label>
              </div>
              {/* <div key={'Diggipacks'}>
            <input
              type="radio"
              name={'Diggipacks'}
              id={`Diggipacks`}
              className="form-radio shrink-0 peer !hidden text"
              hidden
              onClick={()=>setShipType({type:'DG',payment:''})}
            />
            <label
              className={`flex-1 cursor-pointer ${shipType.type==='DG' && 'border-blue-600'} border-2 py-3 px-4 rounded  transition-all block text-sm`}
              htmlFor={`Diggipacks`}
            >
              <div className="flex items-center gap-3 flex-wrap w-full justify-between">
                <p className="text-sm font-semibold">Aliexpress shipping + shipping with special packaging  <span className='text-sm text-blue-500'></span></p>
                <p className="text-sm text-blue-500">{CurrencyFormatter(31)} </p>
              </div>
            </label>
          </div> */}
            </div>
          </div>
          {shipType.type === 'DG' && (
            <div className="flex flex-col w-full">
              <span>Payment Type</span>
              <div className="my-3 gap-y-3 flex gap-x-5">
                <div
                  key={'CC'}
                  className="w-1/3"
                >
                  <input
                    type="radio"
                    name={'CC'}
                    id={`CC`}
                    className="form-radio shrink-0 peer !hidden text"
                    hidden
                    onClick={() => setShipType({ type: 'DG', payment: 'CC' })}
                  />
                  <label
                    className={`flex-1 cursor-pointer ${
                      shipType.payment === 'CC' && 'border-blue-600'
                    } border-2 py-3 px-4 rounded   transition-all block text-sm`}
                    htmlFor={`CC`}
                  >
                    <div className="flex items-center gap-3 flex-wrap w-full justify-between">
                      <p className="text-sm font-semibold">Pay Now</p>
                    </div>
                  </label>
                </div>
                <div
                  className="w-1/3"
                  key={'COD'}
                >
                  <input
                    type="radio"
                    name={'COD'}
                    id={`COD`}
                    className="form-radio shrink-0 peer !hidden text"
                    hidden
                    onClick={() => setShipType({ type: 'DG', payment: 'COD' })}
                  />
                  <label
                    className={`flex-1 cursor-pointer ${
                      shipType.payment === 'COD' && 'border-blue-600'
                    } border-2 py-3 px-4 rounded  transition-all block text-sm`}
                    htmlFor={`COD`}
                  >
                    <div className="flex items-center gap-3 flex-wrap w-full justify-between">
                      <p className="text-sm font-semibold">Cash On Delivery</p>
                      <p className="text-sm text-blue-500">{CurrencyFormatter(2)} </p>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          )}
          {shipType.type === 'AE' ? (
            <ul className="divide-y divide-gray-200 my-2 ">
              {groupShipping.length ? (
                groupShipping.map((group: any, index: number) => {
                  return (
                    <ItemList
                      className="my-3 space-y-5 grid grid-cols-1"
                      key={index}
                    >
                      <div className="flex flex-col  my-2 gap-5">
                        {group.items.map((item: any, index: number) => {
                          return (
                            <div
                              key={index}
                              className="flex items-center gap-4 "
                            >
                              <Image
                                className="w-14 h-14 rounded-full object-cover shrink-0"
                                src={item.thumbnail}
                              />
                              <div className="space-y-1">
                                <p className="text-sm font-semibold">{item.product?.name || '-'}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <Shippings
                        onChange={onShippingChange}
                        shippings={group}
                      />
                    </ItemList>
                  );
                })
              ) : (
                <div className="w-full flex items-center justify-center h-full my-10 ">
                  <TailSpin
                    height="40"
                    width="40"
                    color="#253439"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              )}
              <div className="flex w-full  justify-between items-center gap-x-2">
                <button
                  className="btn-with-icon !text-sm !bg-secondary"
                  type="submit"
                  disabled={disabled}
                >
                  Submit
                </button>
                {error && <span className="text-xs text-red-500">{error}</span>}
              </div>
            </ul>
          ) : (
            <div className="w-full flex flex-col md:place-items-start items-center   relative ">
              {order?.sobuy?.items.map((item: any, index: number) => {
                return (
                  <ItemList
                    className="my-3 space-y-2 grid grid-cols-1  w-full"
                    key={index}
                  >
                    <div className="flex flex-col w-full my-2 gap-5">
                      <div className="w-full flex gap-x-5 items-center justify-between">
                        <div className="flex w-fit gap-x-5 items-center">
                          <Image
                            className="w-20  rounded-full object-cover shrink-0"
                            src={
                              item?.checkMapping?.options.map((opt: any) => opt.sku_image)[0] ||
                              item.imageUrl
                            }
                          />
                        </div>
                        <div className="w-full flex flex-col ">
                          <span className="line-clamp-3 overflow-hidden hover:overflow-visible md:text-base text-xs w-full">
                            {order.items[index].product.name}
                          </span>
                          <div className="w-full flex justify-between items-center md:flex-row flex-col">
                            <div className="flex-1 flex flex-col  gap-3  my-5  w-full">
                              <span className="font-bold text-stone-700">options</span>
                              {item?.checkMapping?.options.map((opt: any, index: number) => (
                                <div
                                  key={index}
                                  className="flex w-full justify-start gap-3 items-start"
                                >
                                  <p className="text-sm text-blue-500 flex-1">
                                    {opt?.sku_property_name}
                                  </p>
                                  <p className="text-sm text-blue-500 flex-1 ">
                                    {opt?.sku_property_value}
                                  </p>
                                </div>
                              ))}
                            </div>
                            <div className="flex-1 w-full flex  md:flex-row gap-3 md:justify-start md:items-start items-center">
                              <p className="text-sm text-blue-500 flex-1">
                                {' '}
                                {item?.checkMapping?.currencyCode}{' '}
                                {item?.checkMapping?.offerSalePrice} x {item?.quantity}
                              </p>
                              <p className="text-sm text-blue-500 flex-1 ">
                                {item?.checkMapping?.currencyCode}{' '}
                                {item?.checkMapping?.offerSalePrice * item?.quantity}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ItemList>
                );
              })}

              {order.sobuy ? (
                <>
                  <div className="flex  gap-3 flex-wrap col-span-full md:w-1/3 flex-col  border-blue-600 border-2 justify-between   p-5 rounded  transition-all  text-sm">
                    <p className="text-sm font-semibold">{order.sobuy.trackingMethod}</p>
                    <div className="justify-between flex items-center w-full">
                      <span> Shipping Price </span>
                      <p className="text-sm text-blue-500">
                        USD {order.sobuy?.sobuyTotalShippingPrice}
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full justify-between md:flex-row flex-col p-3 md:p-0">
                    <div className=" col-span-full flex justify-between items-center gap-y-3 gap-x-3 md:flex-row flex-col  my-5  ">
                      <div className="bg-blue-500 text-white md:w-fit w-full  flex justify-between items-center rounded-lg p-3 gap-10">
                        <span> Products Price </span>
                        <span> USD {order.sobuy?.sobuyTotalPrice}</span>
                      </div>
                      <div className="bg-blue-500 text-white md:w-fit w-full  flex justify-between items-center rounded-lg p-3 gap-5 ">
                        <span>Total Price </span>
                        <span> USD {order.sobuy?.sobuySubtotalPrice}</span>
                      </div>
                    </div>
                    <div className="flex-1 flex w-full col-span-full  md:justify-end justify-center items-center gap-x-2">
                      <span
                        className="btn-with-icon !text-base md:w-1/3 w-full !bg-secondary cursor-pointer"
                        onClick={() => updateSobuyShipping()}
                      >
                        Submit
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
              {error && <span className="text-xs text-red-500">{error}</span>}
            </div>
          )}
          {loading ? (
            <div className="w-full flex items-center justify-center h-full my-10 ">
              <TailSpin
                height="40"
                width="40"
                color="#253439"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            ''
          )}
        </form>
      </Modal>
    </>
  );
}

function Shippings({
  onChange,
  shippings
}: {
  onChange: AllHTMLAttributes<HTMLInputElement>['onChange'];
  shippings: any;
}) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (shippings) setLoading(false);
  }, []);
  if (loading)
    return (
      <div className="w-full flex items-center justify-center h-full my-10 ">
        <TailSpin
          height="40"
          width="40"
          color="#253439"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );

  return (
    <>
      <div className="flex gap-2 flex-wrap">
        {shippings?.shippingMethod?.length ? (
          shippings?.shippingMethod.map((shipping: any, i: any) => (
            <div key={shipping.service_name}>
              <input
                type="radio"
                name={'shipping-method-for-product-' + shippings.store_id}
                id={`${i}+${shippings.store_id}`}
                className="form-radio shrink-0 peer !hidden text"
                hidden
                data-store_id={shippings.store_id}
                data-amount={shipping?.freight.amount || shipping.freight.cent / 100}
                data-service-name={shipping?.service_name}
                data-tracking={
                  shipping?.tracking_available && JSON.parse(shipping?.tracking_available)
                }
                onChange={onChange}
              />
              <label
                className="flex-1 cursor-pointer peer-checked:border-blue-600 peer-checked:border-2 py-3 px-4 rounded border border-gray-200 transition-all block text-sm"
                htmlFor={`${i}+${shippings.store_id}`}
              >
                <div className="flex items-center gap-3 flex-wrap">
                  {shipping.service_name === 'CAINIAO_CONSOLIDATION_SA' ? (
                    <span className="text-red-500 text-sm">( Recommend )</span>
                  ) : null}
                  <p className="text-sm font-semibold">
                    {shipping.service_name === 'CAINIAO_CONSOLIDATION_SA'
                      ? 'AliExpress Direct'
                      : shipping.service_name === 'CAINIAO_STANDARD'
                      ? 'AliExpress Standard Shipping'
                      : shipping.shipping_method
                      ? shipping.shipping_method
                      : shipping.service_name}{' '}
                    {!JSON.parse(shipping.tracking_available) ? (
                      <span className="text-red-500">
                        ({' '}
                        {JSON.parse(shipping.tracking_available) ? 'Tracking not available' : null}{' '}
                        )
                      </span>
                    ) : null}
                  </p>
                  <p className="text-sm text-blue-500">
                    {/* {checkShipping(shipping.service_name,shippings)  ? CurrencyFormatter(0) :CurrencyFormatter( shipping.freight?.amount || shipping.freight?.cent/100) } */}
                    {CurrencyFormatter(shipping.freight?.amount || shipping.freight?.cent / 100)}
                  </p>
                </div>
                <div className="flex justify-between my-1">
                  <p className="text-sm text-gray-500">{shipping.estimated_delivery_time || '-'}</p>
                  {shipping.service_name === 'CAINIAO_STANDARD' ? (
                    <p
                      className="text-red-500 inline"
                      style={{ fontSize: '10px' }}
                    >
                      * Not including customs *
                    </p>
                  ) : null}
                </div>
              </label>
            </div>
          ))
        ) : (
          <p className="text-red-500">Product shipping is not available</p>
        )}
      </div>
    </>
  );
}
