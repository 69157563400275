import { Fragment, useState, useCallback, useEffect, useMemo } from 'react';
import { MoveToTop } from 'src/animations';
import { motion } from 'framer-motion';
import SharedTime from 'src/components/shared/SharedTime';
import Image from 'src/components/shared/Image';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import { Icon } from '@iconify/react';
import Modal from 'src/components/shared/Modal';
import UpdateStatusForm from './components/UpdateStatusForm';
import useOrderHooks from 'src/hooks/orders/id';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import axiosInstance from 'src/helper/AxiosInstance';
import { useAlert } from 'src/hooks/alerts';
import axios, { AxiosError } from 'axios';
import useForm from 'src/hooks/useForm';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import Translate from 'translate';
import { translateCity, getProvince, cities } from 'src/helper/City';
import ProductsShipping from 'src/components/orders/ProductsShipping';
import { RadioGroup } from '@headlessui/react';
import ConvertCurrenct from 'src/hooks/ConvertCurrenct';
import UpdateCustomerDetails from 'src/components/orders/UpdateCustomerDetails';
import * as Yup from 'yup';
import ItemList from 'src/components/shared/ItemList';
import Card from 'src/components/shared/Card';
import UpdateCustomerAddress from 'src/components/orders/UpdateCustomerAddress';
import Table from 'src/components/shared/tables';
import { Link } from 'react-router-dom';
import TrackngTable from './components/TrackngTable';

export default function id() {
  const [statusVisible, setStatusVisible] = useState<boolean>(false);
  const [payVisible, setPayVisible] = useState<boolean>(false);
  const [trackSobuyVisible, setTrackSoBuyVisible] = useState<boolean>(false);
  const [trackVisible, setTrackVisible] = useState<boolean>(false);
  const [DGTrackVisible, setDGTrackVisible] = useState<boolean>(false);
  const [viewStatusModal, setViewStatusModal] = useState<boolean>(false);
  const [city, setCity] = useState('');
  const [citiesData, setCitiesData] = useState([]);
  const [province, setProvince] = useState('');
  const [groupShipping, setGroupShipping] = useState([]);
  const [initialValues, setInitialValues] = useState<any>({
    firstName: '',
    middleName: '',
    lastName: '',
    phone: '',
    zipcode: '',
    street: '',
    district: '',
    city: '',
    province: '',
    shipMethod: ''
  });
  const [shippingProducts, setShippingProducts] = useState<any>([]);

  const instructions = [
    'Before paying for the order, you must know the shipping details for each product',
    'Check your payment information before paying',
    'You can send the request after payment and completing the previous procedures',
    'You can track the order after sending the order to know everything new about the status of the order and shipping as well',
    'Any action taken through your store will not be taken into account within the order procedures. Only the status of the product will be changed within your store from here.',
    'You Should Enter All Data Of Order in English Only'
  ];

  const {
    isLoading,
    order: { order, unpaid_amount, amount_included_vat, vat_value },
    GetOrder
  } = useOrderHooks();

  useEffect(() => {
    (async () => {
      try {
        if (order) {
          const { data, status } = await axiosInstance.get(
            `/aliexpress/order/shipping/${order.id}`
          );
          if (status === 200) setGroupShipping(data.shipping);
          console.log(order);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [order]);

  useEffect(() => {
    (async () => {
      const englishLettersRegex = /^[A-Za-z\s]+$/;
      if (order?.shipping?.address?.city_en) {
        if (englishLettersRegex.test(order?.shipping?.address?.city_en)) {
          setCity(order?.shipping?.address?.city_en);
        } else {
          setCity(translateCity(order?.shipping?.address?.city_en));
        }
      } else {
        setCity(translateCity(order?.shipping?.address?.city));
      }

      let arr: Array<Object> = [];
      order?.items.length &&
        (await Promise.all(
          order.items.map(async (e: any) => {
            try {
              let { data: product } = await axiosInstance.get(`/products/v1/${e.product._id}`);
              await axiosInstance
                .post('/aliexpress/products/shipping', {
                  product_num: e.quantity,
                  product_id: product.product.original_product_id
                })
                .then((res) => {
                  arr.push({ product, shipping: res.data.result });
                });
            } catch (error) {
              console.log(error);
            }
          })
        ));
      setShippingProducts(arr);
    })();

    setCitiesData(Object.values(cities));
  }, [order]);

  useEffect(() => {
    setProvince(
      getProvince(order?.shipping.address.city_en || translateCity(order?.shipping?.address?.city))
    );
  }, [city]);

  const { user } = useSelector((state: RootState) => state.auth);
  const alert = useAlert();

  const handlePrintPage = useCallback(function () {
    window.print();
  }, []);

  // *** shipping fees ****
  const totalUnpaidAmount = amount_included_vat || 0;
  // ******end ******

  if (isLoading) return <LoadingComponent />;

  async function DeleteOrder() {
    try {
      const { data } = await axiosInstance.post('orders/delete', { id: order.id });
      alert.show({
        visible: true,
        text: data?.message
      });
    } catch (error: AxiosError | any) {
      const err = error?.response?.data;

      if (err) {
        alert.show({
          visible: true,
          text: err?.message
        });
      }
    }
  }
  const englishLettersRegex = /^[A-Za-z\s]+$/;
  async function UpdateOrderStatus(status: string, id: string) {
    try {
      const { data } = await axiosInstance.post('orders/update-status', {
        orderId: id,
        merchant: user?.id,
        status
      });

      alert.show({
        text: data.message,
        visible: true
      });
    } catch (error: AxiosError | any) {
      const err = error?.response?.data;

      if (err?.message instanceof Object) {
        console.log('error while updating order => ', err?.message);
      } else {
        alert.show({
          text: err?.message,
          visible: true
        });
      }
    }
  }

  const sendOrderAliExpress = async (id: any) => {
    try {
      const { data } = await axiosInstance.post('/aliexpress/orders/place-order', { id });
      if (data.status === 200) {
        alert.show({
          text: data?.message,
          visible: true
        });
      }
    } catch (error: AxiosError | any) {
      console.log(error);
      const err = error?.response?.data;
      alert.show({
        text: err?.message,
        visible: true
      });
    }
  };

  const handleSoBuyOrder = async () => {
    try {
      await axiosInstance
        .get(`/orders/sobuy/create/${order?.id || order?._id}`)
        .then(({ data }) => {
          if (data.link) window.open(data.link, '_blank');
        });
    } catch (error) {
      console.log(error);
      alert.show({
        text: 'Error While trying to purchase order ! Contact Support',
        visible: true
      });
    }
  };

  const handleSoBuyInvoice = async () => {
    try {
      await axiosInstance
        .post(`/orders/sobuy/invoice/${order?.id || order?._id}`)
        .then(({ data }) => {
          if (data.invoice) window.open(data.invoice, '_blank');
        });
    } catch (error) {
      console.log(error);
      alert.show({
        text: 'Error While trying to purchase order ! Contact Support',
        visible: true
      });
    }
  };

  const CancelDG = async () => {
    try {
      await axiosInstance.get(`orders/cancel_ship/${order.id}`).then((res) => {
        if (res.status === 200) {
          alert.show({
            text: 'Diggipacks Order Canceled ',
            visible: true
          });
        } else {
          console.log(res);
          alert.show({
            text: 'Error Happend ',
            visible: true
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div className="relative p-8 pt-2 print:!p-0">
        <div className="flex flex-col-reverse xl:flex-row items-start gap-4">
          <div className="flex-1 w-full xl:max-w-sm space-y-4 hidden">
            <motion.div
              variants={MoveToTop}
              animate="visible"
              initial="hidden"
              className="rounded-2xl bg-white border border-ring-border shadow-2xl shadow-neutral-800/5 p-5 w-full hidden"
            >
              <p className="text-base font-bold text-title pb-5 border-b border-b-ring-border">
                Payment details
              </p>
            </motion.div>
            <motion.div
              variants={MoveToTop}
              animate="visible"
              initial="hidden"
              className="rounded-2xl bg-white border border-ring-border shadow-2xl shadow-neutral-800/5 p-5 w-full"
            >
              <p className="text-base font-bold text-title pb-5 border-b border-b-ring-border">
                Order history
              </p>
              <ul className="divide-y divide-ring-border">
                {Array.from({ length: 4 }).map((_, index: number) => (
                  <li
                    key={index}
                    className="py-4 last:pb-0"
                  >
                    <div className="flex gap-4 items-center">
                      <div className="relative flex flex-col justify-center items-center">
                        <span className="w-3 h-3 rounded-full bg-primary block shrink-0 relative">
                          <span className="absolute w-full h-full animate-ping bg-primary/50 rounded-full"></span>
                        </span>
                      </div>
                      <div className="flex-1 space-y-1">
                        <p className="text-base font-bold text-title">Status name</p>
                        <SharedTime date={new Date()} />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </motion.div>
          </div>
          <div className="w-full flex-1 space-y-4">
            <div className="flex gap-4 items-center flex-wrap">
              <div className="flex-1">
                <p className="text-lg font-bold text-content">
                  Order <span className="text-sky-600">#{order?.reference_id}</span>
                </p>
                <SharedTime date={order?.createdAt} />
              </div>

              {/* PAYNOW & PRINT Button  */}
              <div className="flex items-center gap-2 flex-wrap">
                {user?.userType === 'admin' && (
                  <>
                    <button
                      className="btn-with-icon outline-btn !text-content"
                      onClick={() => sendOrderAliExpress(order.id)}
                    >
                      <Icon
                        icon="material-symbols-light:send"
                        width={20}
                        height={20}
                        className="text-sub-title"
                      />
                      <p className="text-sm text-content flex-1">Send Order</p>
                    </button>
                    {order?.shipType?.type === 'DG' && order?.status === 'delivering' && (
                      <button
                        className="btn-with-icon outline-btn !text-content"
                        onClick={() => CancelDG()}
                      >
                        <Icon
                          icon="ic:baseline-close"
                          width={20}
                          height={20}
                          className="text-sub-title"
                        />
                        <p className="text-sm text-content flex-1">Cancel Shipment</p>
                      </button>
                    )}
                  </>
                )}
                {order?.status === 'created' && user?.userType === 'vendor' ? (
                  <button
                    className="btn-with-icon outline-btn !text-content"
                    onClick={() => {
                      order.shipType.type === 'Sobuy' ? handleSoBuyOrder() : setPayVisible(true);
                    }}
                  >
                    <Icon
                      icon="fluent:payment-16-regular"
                      width={20}
                      height={20}
                      className="text-sub-title"
                    />
                    <p className="text-sm text-content flex-1">Pay now</p>
                  </button>
                ) : (
                  <>
                    {order.shipType.type === 'AE' ? (
                      <button
                        className="btn-with-icon outline-btn !text-content"
                        onClick={() => setTrackVisible(true)}
                      >
                        <Icon
                          icon="streamline:transfer-van"
                          width={20}
                          height={20}
                          className="text-sub-title"
                        />
                        <p className="text-sm text-content flex-1">Track Aliexpress Shipping</p>
                      </button>
                    ) : (
                      <button
                        className="btn-with-icon outline-btn !text-content"
                        onClick={() => {
                          setTrackSoBuyVisible(true);
                          console.log(trackSobuyVisible);
                        }}
                      >
                        <Icon
                          icon="streamline:transfer-van"
                          width={20}
                          height={20}
                          className="text-sub-title"
                        />
                        <p className="text-sm text-content flex-1">SoBuy Shipping</p>
                      </button>
                    )}

                    {order?.shipType?.type === 'DG' && order?.status === 'delivering' && (
                      <button
                        className="btn-with-icon outline-btn !text-content"
                        onClick={() => setDGTrackVisible(true)}
                      >
                        <Icon
                          icon="streamline:transfer-van"
                          width={20}
                          height={20}
                          className="text-sub-title"
                        />
                        <p className="text-sm text-content flex-1">Track Local Shipping</p>
                      </button>
                    )}
                  </>
                )}
                {order.shipType.type === 'Sobuy' && order.sobuy.status > 0 && (
                  <button
                    className="btn-with-icon outline-btn !text-content"
                    onClick={handleSoBuyInvoice}
                  >
                    <Icon
                      icon="uil:invoice"
                      width={20}
                      height={20}
                      className="text-sub-title"
                    />
                    <p className="text-sm text-content flex-1">Sobuy Invoice</p>
                  </button>
                )}
                <button
                  className="btn-with-icon outline-btn !text-content"
                  onClick={handlePrintPage}
                >
                  <Icon
                    icon="ion:print-outline"
                    width={20}
                    height={20}
                    className="text-sub-title"
                  />
                  <p className="text-sm text-content flex-1">Print</p>
                </button>
              </div>
            </div>
            <motion.div
              variants={MoveToTop}
              animate="visible"
              initial="hidden"
              className="rounded-2xl bg-white border border-ring-border shadow-2xl shadow-neutral-800/5 p-5 w-full"
            >
              <p className="text-base font-bold text-title pb-5 border-b border-b-ring-border">
                Customer & shipping details
              </p>

              <form>
                <Card className="space-y-4">
                  <div className="space-y-2">
                    <p className="text-lg font-semibold">Instructions while creating the order</p>
                    <ul className="list-disc ps-5 space-y-1">
                      {instructions.map((item, index) => (
                        <li key={index}>
                          <p className="text-sm text-gray-500">{item}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Card>
                <div className="grid md:grid-cols-2 gap-x-2 grid-cols-1 items-start">
                  <Card className="space-y-4  my-2">
                    <div className="flex justify-between items-center gap-4 pb-3 border-b border-b-gray-200 ">
                      <p className=" text-sm font-semibold">Customer details</p>
                      <UpdateCustomerDetails
                        order={order}
                        refetch={GetOrder}
                      />
                    </div>
                    <ul className="divide-y divide-gray-200">
                      <ItemList className="py-3 space-y-1">
                        <div className="grid grid-cols-2">
                          <p className="text-sm text-gray-500">First Name</p>
                          <p className="text-sm font-semibold text-end break-all">
                            {englishLettersRegex.test(order?.customer?.first_name)
                              ? order?.customer?.first_name
                              : '-'}
                          </p>
                        </div>
                      </ItemList>
                      <ItemList className="py-3 space-y-1">
                        <div className="grid grid-cols-2">
                          <p className="text-sm text-gray-500">Last Name</p>
                          <p className="text-sm font-semibold text-end break-all">
                            {englishLettersRegex.test(order?.customer?.last_name)
                              ? order?.customer?.last_name
                              : '-'}
                          </p>
                        </div>
                      </ItemList>
                      <ItemList className="py-3 space-y-1">
                        <div className="grid grid-cols-2">
                          <p className="text-sm text-gray-500">Mobile</p>
                          <p className="text-sm font-semibold text-end break-all">
                            {`${order?.customer?.mobile_code}${order?.customer?.mobile}`}
                          </p>
                        </div>
                      </ItemList>
                      <ItemList className="py-3 space-y-1">
                        <div className="grid grid-cols-2">
                          <p className="text-sm text-gray-500">E-mail address</p>
                          <p className="text-sm font-semibold text-end break-all">
                            {order?.customer?.email || '-'}
                          </p>
                        </div>
                      </ItemList>
                    </ul>
                  </Card>
                  <Card className="space-y-4 my-2">
                    <div className="flex justify-between items-center gap-4 pb-3 border-b border-b-gray-200">
                      <p className=" text-sm font-semibold">Shipping address</p>
                      <UpdateCustomerAddress
                        order={order}
                        refetch={GetOrder}
                        getProvince={getProvince}
                        citiesData={citiesData}
                      />
                    </div>
                    <ul className="divide-y divide-gray-200">
                      <ItemList className="py-3 space-y-1">
                        <div className="grid grid-cols-2">
                          <p className="text-sm text-gray-500">Country</p>
                          <p className="text-sm font-semibold text-end break-all">Saudi Arabia</p>
                        </div>
                      </ItemList>
                      <ItemList className="py-3 space-y-1">
                        <div className="grid grid-cols-2">
                          <p className="text-sm text-gray-500">City</p>
                          <p className="text-sm font-semibold text-end break-all">{city}</p>
                        </div>
                      </ItemList>
                      <ItemList className="py-3 space-y-1">
                        <div className="grid grid-cols-2">
                          <p className="text-sm text-gray-500">Province</p>
                          <p className="text-sm font-semibold text-end break-all">
                            {order?.shipping?.address?.province_en || province}
                          </p>
                        </div>
                      </ItemList>
                      <ItemList className="py-3 space-y-1">
                        <div className="grid grid-cols-2">
                          <p className="text-sm text-gray-500">Zip / Postal code </p>
                          <p className="text-sm font-semibold text-end break-all">
                            {order?.shipping?.address?.postal_code !== 'لايوجد'
                              ? order?.shipping?.address?.postal_code
                              : '-'}
                          </p>
                        </div>
                      </ItemList>
                      <ItemList className="py-3 space-y-1">
                        <div className="grid grid-cols-2">
                          <p className="text-sm text-gray-500">District</p>
                          <p className="text-sm font-semibold text-end break-all">
                            {order?.shipping?.address?.district_en?.length > 4
                              ? order?.shipping?.address?.district_en
                              : '-'}
                          </p>
                        </div>
                      </ItemList>
                      <ItemList className="py-3 space-y-1">
                        <div className="grid grid-cols-2">
                          <p className="text-sm text-gray-500">Street</p>
                          <p className="text-sm font-semibold text-end break-all">
                            {order?.shipping?.address?.street_en?.length > 4
                              ? order?.shipping?.address?.street_en
                              : '-'}
                          </p>
                        </div>
                      </ItemList>
                    </ul>
                  </Card>
                </div>
                <Card className="space-y-4">
                  <div className="flex items-center justify-between gap-4 pb-3 border-b">
                    <p className=" border-b-gray-200 text-sm font-semibold">Products Shipping</p>
                    <ProductsShipping
                      order={order}
                      refetch={GetOrder}
                    />
                  </div>
                  <ul className="divide-y divide-gray-200">
                    {order?.items?.map((item: any, i: number) => (
                      <ItemList
                        className="py-3 space-y-1"
                        key={i}
                      >
                        <div className="flex items-center gap-2">
                          <div className="flex-1">
                            <div className="flex items-center gap-4">
                              <Image
                                className="w-14 h-14 rounded-full object-cover shrink-0"
                                src={item.thumbnail}
                              />
                              <div className="space-y-3">
                                <div className="space-y-1">
                                  <p className=" font-semibold text-sm">
                                    {item.product?.name || '-'}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p className="text-sm font-semibold text-end break-all">
                            {order.shipType.type === 'Sobuy'
                              ? 'USD ' + (item?.product?.shipping?.amount || '0')
                              : CurrencyFormatter(item?.product?.shipping?.amount || 0)}
                          </p>
                        </div>
                      </ItemList>
                    ))}
                  </ul>
                </Card>
              </form>
            </motion.div>
            <motion.div
              variants={MoveToTop}
              animate="visible"
              initial="hidden"
              className="rounded-2xl bg-white border border-ring-border shadow-2xl shadow-neutral-800/5 p-5 w-full"
            >
              <p className="text-base font-bold text-title pb-5 border-b border-b-ring-border">
                Order items
              </p>
              <ul className="divide-y divide-ring-border">
                {order?.items?.map((item: any, i: number) => (
                  <li
                    key={i}
                    className="py-4 last:pb-0"
                  >
                    <div className="flex gap-4 items-start flex-col sm:flex-row">
                      <Image
                        src={item?.thumbnail}
                        className="w-20 h-20 rounded-lg object-cover border border-ring-border shrink-0"
                      />
                      <div className="flex-1 shrink-0 gap-8 flex flex-col sm:flex-row">
                        <div className="flex-1 space-y-1">
                          <p className="text-base font-semibold text-title line-clamp-2 !mb-3">
                            {item?.product?.name}
                          </p>
                          <p className="text-sm text-content grid grid-cols-2 gap-2">
                            <span className="text-gray-500">Product price:</span>{' '}
                            <span className="font-semibold text-title inline-block">
                              {CurrencyFormatter(Number(item?.product.price || 0))}
                            </span>
                          </p>
                          <p className="text-sm text-content grid grid-cols-2 gap-2">
                            <span className="text-gray-500">Main price:</span>{' '}
                            <span className="font-semibold text-title inline-block">
                              {/* {CurrencyFormatter(
                                Number(item?.product?.price || 0) -
                                  Number(
                                    order?.meta[item?.product?.salla_product_id]?.vendor_price || 0
                                  )
                              )} */}
                              {CurrencyFormatter(Number(item?.product.main_price || 0))}
                            </span>
                          </p>
                          <p className="text-sm text-sub-content grid grid-cols-2 gap-2">
                            <span className="text-gray-500">SKU:</span>
                            <span className="font-semibold text-title inline-block truncate break-all">
                              {item?.sku}
                            </span>
                          </p>
                          <p className="text-sm text-sub-content grid grid-cols-2 gap-2">
                            <span className="text-gray-500">Quantity:</span>
                            <span className="font-semibold text-title inline-block">
                              {item?.quantity}
                            </span>
                          </p>
                          <p className="text-sm text-title !my-3 font-bold">Options</p>
                          {item?.options?.map((option: any) => (
                            <p
                              key={option?.id}
                              className="text-sm text-sub-content grid grid-cols-2 gap-2"
                            >
                              <span className="text-gray-500">{option?.name}:</span>
                              <span className="font-semibold text-title inline-block">
                                {option?.value?.name || '-'}
                              </span>
                            </p>
                          ))}
                          {/* End options */}
                          {item?.notes && (
                            <details className="cursor-pointer !mt-3">
                              <summary className="text-sm text-title">Notes</summary>
                              <p className="text-sm text-sub-content mt-2">{item?.notes}</p>
                            </details>
                          )}
                        </div>
                        <div className="space-y-1 hidden">
                          <p className="text-sm font-semibold text-gray-600">
                            {CurrencyFormatter(Number(item?.product?.price || 0))}
                          </p>
                          <p className="text-lg font-bold text-teal-600">
                            {CurrencyFormatter(item?.product?.price)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <ul className="mt-4 border-t border-t-ring-border pt-2">
                <li className="pt-2">
                  <div className="flex gap-4 items-start">
                    <div className="space-y-0.5 flex-1">
                      <p className="text-sm text-content font-semibold">Required amount</p>
                      <p className="text-sm text-red-500">
                        Amount included %{vat_value} VAT equal{' '}
                        {order.shipType.type === 'AE'
                          ? CurrencyFormatter(
                              parseFloat(
                                Number((unpaid_amount || 0) - (amount_included_vat || 0)).toFixed(2)
                              )
                            )
                          : 0}
                      </p>
                      <p className="text-sm text-red-500">
                        Amount included Shipping fees equal (
                        {order.shipType.type === 'Sobuy'
                          ? 'USD ' + +(order?.shippingFee || '0')
                          : CurrencyFormatter(order?.shippingFee | 0)}
                        )
                      </p>
                    </div>
                    <p className="text-base font-bold text-title">
                      {order.shipType.type === 'Sobuy'
                        ? 'USD ' + order?.sobuy?.sobuySubtotalPrice || 0
                        : CurrencyFormatter(totalUnpaidAmount + order?.shippingFee)}
                    </p>
                  </div>
                </li>
              </ul>
            </motion.div>
          </div>
        </div>
      </div>

      <Modal
        visible={statusVisible}
        title="Update status"
        handleClose={() => setStatusVisible(false)}
      >
        <Fragment>
          <UpdateStatusForm order={order} />
        </Fragment>
      </Modal>
      {/* <Modal
        visible={payVisible}
        title="Payment details"
        handleClose={() => setPayVisible(false)}
      >
        <Fragment>
          <PayForm />
        </Fragment>
      </Modal> */}
      <Modal
        visible={payVisible}
        handleClose={() => setPayVisible(false)}
        title={'#' + order?.reference_id}
      >
        <Fragment>
          <PlaceOrderForm
            item={order}
            unpaid_amount={totalUnpaidAmount}
          />
        </Fragment>
      </Modal>

      {order.shipType.type === 'AE' ? (
        <Modal
          visible={trackVisible}
          handleClose={() => setTrackVisible(false)}
          title={'Order Tracking Status'}
        >
          <TrackngTable
            order={order}
            type={'AE'}
          />
        </Modal>
      ) : order.shipType.type === 'Sobuy' ? (
        <Modal
          visible={trackSobuyVisible}
          handleClose={() => setTrackSoBuyVisible(false)}
          title={'Order Tracking Status'}
        >
          <TrackngTable
            order={order}
            type={'Sobuy'}
          />
        </Modal>
      ) : (
        ''
      )}

      <Modal
        visible={DGTrackVisible}
        handleClose={() => setDGTrackVisible(false)}
        title={'Order Tracking Status'}
      >
        <TrackngTable
          order={order}
          type={'DG'}
        />
      </Modal>
    </Fragment>
  );

  function PlaceOrderForm({ item, unpaid_amount }: any) {
    const [disabled, setDisabled] = useState<boolean>(false);
    const [errors, setErrors] = useState<any>();
    const [cards, setCards] = useState([]);
    const [orderCard, setOrderCard] = useState('');
    useEffect(() => {
      (async () => {
        const { data } = await axiosInstance.get(`/v1/payments/cards`);
        if (data.length) {
          setCards(data);
        }
      })();
    }, []);
    const {
      formik: { handleSubmit }
    } = useForm({ initialValues: { id: item?.id }, submitHandler: SendOrder });

    async function SendOrder(val: any, helpers: any) {
      try {
        if (!orderCard) return setErrors('Please Choose Payment Method');
        setDisabled(true);
        setErrors(undefined);
        const { data } = await axiosInstance.post(`orders/pay-order?card=${orderCard}`, val);

        if (data.url) {
          window.location.href = data.url;
        } else {
          alert.show({
            text: data.message,
            visible: true
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } catch (error: AxiosError | any) {
        const err = error.response;
        if (error instanceof AxiosError) {
          console.log(err.data);
          setErrors(err.data.message);
        }
      } finally {
        setDisabled(false);
      }
    }

    return (
      <form
        className="space-y-4"
        onSubmit={handleSubmit}
      >
        <div className="flex items-center justify-center w-20 h-20 rounded-full bg-gray-100 text-gray-600 mx-auto space-y-2">
          <Icon
            icon="entypo:hour-glass"
            width={26}
            height={26}
          />
        </div>
        <div className="space-y-2">
          <p className="text-lg text-title font-semibold text-center">Send order</p>
          <p className="text-sm text-content font-medium text-center">
            The request will be sent to the administration first so that the necessary procedures
            for that request are followed and the required is verified, then you will be notified of
            the new, you must pay the next amount{' '}
            <span className="font-bold text-teal-600">
              {CurrencyFormatter(unpaid_amount + order.shippingFee || 0)}
            </span>{' '}
            and verify first that that amount is already available on the credit card
          </p>
          {errors ? <p className="form-error text-center !block">{errors}</p> : null}
        </div>
        <div>
          <Card className="mb-3">
            <div className="text-xl font-bold">Cards</div>
            <div className="grid grid-cols-2 py-2  gap-3 my-2">
              {cards.length
                ? cards.map((card: any, index: number) => {
                    return (
                      <div
                        key={index}
                        className={` flex items-center justify-around cursor-pointer rounded-lg p-2 `}
                        style={
                          card.id === orderCard
                            ? { border: '1px solid blue' }
                            : { border: '1px solid #eee' }
                        }
                        onClick={() => setOrderCard(card.id)}
                      >
                        {card.payment_method === 'Mastercard' ? (
                          <Icon
                            icon="logos:mastercard"
                            width="30"
                          />
                        ) : card.payment_method === 'Visa' ? (
                          <Icon
                            icon="logos:visa"
                            width="30"
                          />
                        ) : card.payment_method === 'American_express' ? (
                          <Icon
                            icon="simple-icons:americanexpress"
                            width="30"
                          />
                        ) : card.payment_method === 'Mada' ? (
                          <span className="text-blue-400">مدي</span>
                        ) : (
                          <span>{card.payment_method}</span>
                        )}
                        {card.first_eight + '********'}
                      </div>
                    );
                  })
                : null}
              <div
                className=" flex items-center justify-around cursor-pointer rounded-lg px-2 py-1 "
                style={
                  orderCard === 'default'
                    ? { border: '1px solid blue' }
                    : { border: '1px solid #eee' }
                }
                onClick={() => setOrderCard('default')}
              >
                Pay with new card
              </div>
            </div>
          </Card>
          <div className="flex gap-2 flex-wrap">
            <button
              className="btn-with-icon !text-sm !bg-secondary flex-[fit-content]"
              type="submit"
              disabled={disabled}
            >
              Submit
            </button>
            <button
              className="btn-with-icon !text-sm outline-btn flex-[fit-content]"
              type="button"
              disabled={disabled}
              onClick={() => setPayVisible(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    );
  }
}
